<template>
  <div class="main-content-wrap import-list">
    <div class="ShoppingCartEmpty_title">
      <div class="mycart">
        My cart
      </div>

      <div class="tips">
        You are eligible for free shipping!
      </div>

    </div>

    <div class="ShoppingCartEmpty_content">
      <div class="ShoppingCartEmpty_img">
        <img src="" alt="">
      </div>

      <div class="ShoppingCartEmpty_tips">
        Your shopping cart is empty
      </div>

      <div class="ShoppingCartEmpty_tips_two">
        Fortunately, there's an easy solution.
      </div>

      <div class="ShoppingCartEmpty_btn">
        <el-button class="Go_Shopping_btn">Go Shopping</el-button>
      </div>
    </div>

  </div>
</template>
<script>
  export default {

  }

</script>
<style lang="scss" scoped>
  .import-list {
    padding-top: 25px;
  }

  .main-content-wrap {
    width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .ShoppingCartEmpty_title {
    display: flex;
    flex-direction: column;

    .mycart {
      font-size: 26px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #000000;
    }

    .tips {
      margin-top: 10px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      text-align: left;
      color: #000000;
    }
  }

  .ShoppingCartEmpty_content {
    margin-top: 24px;
    height: 700px;
    background: #ffffff;
    border: 1px solid #dedede;
    // display: flex;
    align-content: center;
    justify-content: center;

    .ShoppingCartEmpty_img {
      margin-top: 134px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 70px;
        height: 40px;
        background: blue;
      }
    }

    .ShoppingCartEmpty_tips {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #999999;
      margin-top: 31px;
    }

    .ShoppingCartEmpty_tips_two {
      margin-top: 10px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #1a1a1a;
    }

    .ShoppingCartEmpty_btn {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .Go_Shopping_btn {
        width: 223px;
        height: 44px;
        background: #ff6c23;
        color: white;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
      }
    }

  }

</style>
